* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  font-family: 'Assistant';
}

*:focus {
  outline: none;
}
a,
a:visited {
  text-decoration: none;
}

.image-cropper {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
}
.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* cursor: pointer; */
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

div,
p,
span,
li,
h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
dt,
dd {
  text-align: start;
  /* unicode-bidi: plaintext; */
}

li[dir='RTL'],
p[dir='RTL'],
span[dir='RTL'] {
  text-align: right !important;
}
